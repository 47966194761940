import { useFormik } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";

import ErrorMsg from "./error-msg";
import { loginSchema } from "./validation-schema";
import {
  forgetPassword,
  login,
  updatePassword,
} from "../../../services/user-service";
import { setDataInLocalStorage } from "../../../browser-storage/local-storage";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
const PasswordResetForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //not being used
  // const from = location.state?.from?.pathname || "/";
  // console.log("check from value", from);
  // console.log(location, " useLocation Hook");
  //  const SingleCourse = location.state?.data;
  //console.log("SingleCourse", SingleCourse);
  // let DetailsonChange = (evt) => {
  //   console.log("evt", evt.target.value);
  //   setEmail({ ...email, [evt.target.name]: evt.target.value });
  // };

  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [newPassword, setPassword] = useState("");
  const { userEmail, token } = useParams(); // Assuming you are using React Router for routing

  useEffect(() => {
    // const token = window.location.pathname.split("/")[2]; // Extract token from URL
    // setResetToken(token);
    const getEmailAndTokenFromLink = () => {
      // userEmail already contains the user's email before the token
      console.log("User Email:", userEmail);
      console.log("Token:", token);
      setResetToken(token);
      setEmail(userEmail);
      //   const tokenParts = token.split("/");
      //   console.log("Token Parts:", tokenParts);
      //   if (tokenParts.length >= 2) {
      //     const resetTokenFromLink = tokenParts[1];
      //     console.log("Reset Token from Link:", resetTokenFromLink);
      //     setResetToken(resetTokenFromLink);

      //     // Check if email is present in the token
      //     const emailIndex = tokenParts.findIndex((part) => part.includes("@"));
      //     if (emailIndex !== -1) {
      //       const emailFromToken = decodeURIComponent(tokenParts[emailIndex]);
      //       console.log("Email from Token:", emailFromToken);
      //       setEmail(emailFromToken);
      //     }
      //   }
      // };
    };

    getEmailAndTokenFromLink();
  }, [token]);

  // contact form
  const handleOnSubmit = async () => {
    const payload = {
      email: email,
      current_source: window.location.hostname,
    };
    const loginResponse = await forgetPassword(payload)
      .then((resp) => {
        console.log(resp);
        toast.success(`Please check your mail, your forget password link `, {
          position: "top-left",
        });
      })
      .catch((err) => {
        toast.error(`Error : Please check your username and password `, {
          position: "top-left",
        });
      });
    console.log("loginResponse", loginResponse);
  };

  const handleResetPassword = async (newPassword) => {
    const payload = {
      newPassword: newPassword,
      email: email,
      token: resetToken,
    };
    const resetpass = await updatePassword(payload)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {resetToken ? (
        <>
          <input
            type="password"
            className="form-control"
            placeholder="Enter your new password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="e-btn"
            onClick={() => handleResetPassword(newPassword)}
          >
            Reset Password
          </button>
        </>
      ) : (
        <>
          <div className="sign__input-wrapper mb-25">
            <h5>Username</h5>
            <div className="sign__input">
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="e-mail address"
                name="email"
                autocapitalize="none"
              />
              <i className="fal fa-envelope"></i>
            </div>
          </div>
          {/* <button onClick={handleResetPassword}>Reset Password</button> */}
          <button onClick={handleOnSubmit} className="e-btn w-100">
            Submit
          </button>
        </>
      )}

      <div className="sign__new text-center mt-20">
        <p>
          New to our Platform? <Link to="/student-signup">Sign Up</Link>
        </p>
      </div>
    </>
  );
};

export default PasswordResetForm;
